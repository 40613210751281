$gallery-height:200px;

.main-header {
    
    &__gallery {
        position: absolute;
        bottom:$box-padding-s;
        width:100%;
        height:$gallery-height;
        overflow:hidden;
        left:0;
        }
    }

.gallery {
    width:100%;
    display:none;
    flex-direction: column;
    
    &__item {
        height:$gallery-height;
        width:100%;
        overflow:hidden;
        }
    }

@media screen and (min-width:$screen-xs-max) { // 768
    $gallery-height:200px;
    
    .gallery {
        display: flex;
        flex-direction:row;
        justify-content:space-between;
        overflow:hidden;
        
        &__item {
            width: 30%;
            }
        }
    
    }

@media screen and (min-width: $screen-lg-min) {   // 1200
    
    .main-header__gallery {
        bottom:$box-padding-s;
        }
    }