
.header-page {
   $headerheight: 70vh;
   height:$headerheight;
   position: relative;

  .container {
    padding:0;
  }
    
    &__content {
        position: absolute;
        bottom:0;
        width: 100%;
        padding: 0 $box-padding-s;
        }

   &__image {
     width:100%;
     overflow:hidden;
     height:$headerheight;

     & > * {
         height:100%;
         width:100%;
         object-fit:cover;
         object-position: 50% 50%;
     }
   }
 }


@media screen and (min-width: $screen-md-min) { // 992
  


  .header-page {
      .container {
          display:grid;
          grid-template-columns: 1fr 780px;
          align-items: center;
          }
      
      &__content {
          position: relative;
          height:100%;
          padding:0;
          }

    &__image {

      & > * {
        height:100%;
          width:100%;
          object-fit:cover;
          object-position: 50% 50%;
      }
    }
  }
  
}


@media screen and (min-width: $screen-lg-min) {   // 1200
    
    .header-page {
        .container {
            grid-template-columns: 1fr 860px;
            }
        }
    
    }