.main-header {
  color: #fff !important;
  background:lightgrey;
  height:35vh;
  position: relative;

  .title {
    color: #fff;
    font-size: 2em;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
  }

  &__panel {
    margin: 15px 0;
    padding: 15px;
    max-width: 420px;
    background-color: rgba($brand-primary, .65);
  }

  &__gallery {
    position: relative;
    z-index: 1;
    > * {
      position: relative;
      z-index: 1;
    }
  }

  &--has-image {
    position: relative;
    overflow:hidden;
  }

  &__carousel {
    width:100%;
    position: absolute;
    height:100%;
    left:0;
    top:0;
  }

  &__image {
    position: absolute;
    width:100%;
    height:100%;
  }

  &__image:before {
    position: absolute;
    content:'';
    background:rgba(0,0,0,0.25);
    height:100%;
    width:100%;
  }

  &--page {
    height:35vh;
  }
}

.caption {
  position: absolute;
  bottom:10px;
  display: block;
  color:$color-body;
  max-width: calc(100% - 40px);

  &__title,
  &__content {
    max-width:100%;
    color:white;
  }

  &__title {
    font-size: 1.6em;
    width:100%;
    max-width:700px;
    margin:0 auto;
  }
  
  &--is-centered {
    display: block;
    text-align: center;
    }

  &--is-centered > * {
    display: inline-block;
    margin:0 auto;
    }

}

.action {

  &__bar {
    background: $color-primary;
    position: absolute;
    width: 100%;
    color: white;
    padding: 10px 25px;
    text-align: center;
    left: 0;
    top: $menuh;
    font-size: 18px;
    font-weight: bold;
    }

  &__button {
    background: $color-primary;
    position: absolute;
    //display: flex;
    display: none;
    flex-direction: column;
    width:120px;
    height:120px;
    border-radius:50%;
    top:165px;
    right:20px;
    text-align: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    }
  }


@media screen and (min-width:$screen-xs-min) { // 480

  .main-header--product {
    .caption {
      bottom:10px;
    }
  }
}

@media screen and (min-width:$screen-xs-max) { // 768

  .caption {
    //  bottom:240px;
  }

  .main-header {
    &--product {
      max-height:450px;
    }
  }

}


@media screen and (min-width: $screen-md-min) { // 992


  .main-header {

    .title {
      font-size: 3em;
    }
  }

  .caption {
    max-width: calc(100% - 240px);

    &--main {
      left:0;
    }

    &--page {
      padding: 0 $box-padding-m;
    }

    &__title,
    &__content {
      max-width:700px;
    }

    &__title {
      font-size: 2.4em;
      line-height:1.1em;
    }

    &__content {
      font-size:1.7em;
    }
  }
  .main-header {
    padding-bottom: $box-padding-s;
    padding-top: 15px;
    position: relative;
    z-index: 10;

    &__caption {
      bottom:60px;
    }
    &--has-image {
      padding-bottom: 0;
    }
    &__title, &__panel {
      margin: $box-padding-m 0;
    }

    &--has-gallery {
      z-index: 10;
      .main-header__caption {
        bottom:230px;
      }
    }
  }

  .action {

    &__bar {
      top: $menuheight;
      font-size:25px;
      }

    &__button {
      width:200px;
      height:200px;
      top:auto;
      bottom:50px;
      font-weight: bold;
      font-size: 20px;
      }
    }

}

@media screen and (min-width: $screen-lg-min) {   // 1200

  .main-header--product .caption {
    bottom:$box-padding-m;
  }

  .caption {
    max-width: 100%;
    }
}



@media screen and (max-height:900px) and (min-width: $screen-md-min) { // 850 hoog
  .main-header {
    height: 40vh;
  }
  .caption {
    // bottom:100px;
  }

}
