/* Aligns */
@mixin generate-aligns($s) {
  .align-#{$s}-l { text-align: left; }
  .align-#{$s}-c { text-align: center; }
  .align-#{$s}-r { text-align: right; }

  .pull-#{$s}-left  { float: left; }
  .pull-#{$s}-right { float: right; }
  .pull-#{$s}-none  { float: none; }
}

@include generate-aligns(xs);

@media (min-width: $screen-sm-min) {
  @include generate-aligns(sm);
}
@media (min-width: $screen-md-min) {
  @include generate-aligns(md);
}
@media (min-width: $screen-lg-min) {
  @include generate-aligns(lg);
}