
.tiles {
      display: flex;
      flex-wrap: wrap;
      width:100%;
      position: relative;

      &__col {
            padding-left: 15px;
            padding-right: 15px;
            width: 100/1 * 1%;
            &--has-mg {
                  margin-bottom: 30px;
            }
      }
}


.row-tiles {

      display:flex;
      flex-direction:column;

      padding-top:$row-padding;
      padding-bottom:$row-padding;

      &__tile {
            margin-bottom:$row-padding;
      }

}

.tile {
      display: flex;
      flex-direction: column;
      border:1px solid $color-bg-lightgrey;
      margin-bottom:10px;
      position: relative;
      min-height: 100%;
      transition: border, 0.3s ease;

      &__button {
            margin-bottom:0;
      }

      &__image {
            height:300px;
      }

      &__title {
            margin:0;
            padding:calc(#{$tile-padding} * 2) $tile-padding calc(#{$tile-padding} / 2);
            font-size:1.2em;
            line-height:1.3em;
      }

      &__overlay {
            @include setGradient($color-gradient-start, $color-gradient-end);
            position: absolute;
            width:80px;
            height:80px;
            right:0;
            top:0;
      }

      &__overlay > * {
            position: absolute;
            width:100%;
            text-align: center;
            top:50%;
            transform:translateY(-50%);
            color:white;
            font-weight: bold;
            font-size:20px;
      }

      &__content {
            padding:0 $tile-padding 0;
      }

      &__content > * {
            padding-bottom:0;
            margin:0;
      }

      &__footer {
            padding:0 $tile-padding $tile-padding;
      }

      .image-container--top {
            background-position: top center;
            }

      //&:focus-within {
      //      border:1px solid darken($color-bg-lightgrey, 30%);
      //}
      //
      //&:hover {
      //      border:1px solid darken($color-bg-lightgrey, 30%);
      //      cursor:pointer;
      //
      //      :link,
      //      :visited {
      //            color:white;
      //            background-color:$color-cta-dark;
      //      }
      //}
}


@media screen and (min-width:$screen-xs-max) { // 768
      $tile-padding: 25px;


      .tiles {
            &__col {
                  width: 100/2 * 1%;
            }
      }

      .row-tiles {
            flex-direction: row;
            flex-wrap:wrap;

            &__tile {
                  width:calc(33% - 30px);
                  margin-bottom:$tile-padding;
            }

            &__tile:nth-child(3n+2) {
                  margin-left:20px;
                  margin-right:20px;
            }
      }

      .tile {
            display: flex;
            flex-direction: column;

            &__header {
            }

            &__image {
                  height:280px;
            }

            &__button {
                  align-content: flex-end;
            }

            &__title {
                  margin:0;
                  padding:calc(#{$tile-padding} * 2) $tile-padding calc(#{$tile-padding} / 2);
                  font-size:1.4em;
                  line-height:1em;
            }

            &__content {
                  flex-grow:1;
                  padding:0 $tile-padding;
            }

            &__footer {
                  padding:0 $tile-padding $tile-padding;
            }
      }
}

@media screen and (min-width: $screen-md-min) { // 992
      .tiles {
            width:calc(100% + 15px);
            margin-left:-15px;

            &__col {
                  width: 100/3 * 1%;
            }
      }
}

@media screen and (min-width: $screen-lg-min) {   // 1200

      .row-tiles {
            $row-padding: 100px;
            $row-margin: calc(#{$row-padding} / 2);

            padding-top:$row-padding;
            padding-bottom:$row-margin;


            &__tile {
                  margin-bottom:$row-margin;
            }

            &__tile:nth-child(3n+2) {
                  margin-left:$box-padding-m;
                  margin-right:$box-padding-m;
            }
      }
}
