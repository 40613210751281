.footer {
  color:$color-bg-darkgrey;
  background:white;
  padding-top:$gap;
  padding-bottom: calc(#{$gap} / 2);
  
  &__table {
    max-width:230px;
    }
  
  &__title {
    margin-bottom:$box-padding-half;
    color:$color-primary;
    font-size:1.4em;
    }
  
  &__col {
    margin-bottom:$box-padding-s;
  
    & > * {
      margin-top: 0;
      }
    }
  
  &__list {
    display: block;
    margin:0;
    padding:0;
    }
  
  &__list-item {
    display: block;
    }
  
  :link,
  :visited {
    color:$color-body;
    }
  
  :link:hover,
  :visited:hover {
    color:$color-primary;
    }
  
  .footer__secondary {
    font-size:0.8em;
  
    .footer__list-item {
      margin-right:calc(#{$gap} / 2);
      line-height:2em;
      }
    
    .footer__list-item:last-child {
      margin-top:calc(#{$gap} / 2);
      margin-right:0;
      }
      
    }
  
  &__logo > * {
    max-width:100%;
    }
  }


@media screen and (min-width: $screen-md-min) { //992
  
  .footer {
    
    &__primary {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height:150px;
      }
    
    &__logo {
      width:400px;
      order:1;

      & > * {
        max-width:300px;
        }
      }
    
    &__footernav {
      width:calc(100% - 400px);
      order:3;
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      }
    
    &__footnav {
      width:400px;
      order:2;
      font-size:0.8em;
      }
    
    &__title {
      margin-top:0;
      }
    
    }
  
  .footnav {
    &__list-item {
      display: inline-block;
      margin-left:15px;
      }
    
    &__list-item:nth-child(1) {
      margin-left:0;
      }
    }
  
  }