.product-overview,
.product-detail {
    background:white;
    padding-top:$gap;
    padding-bottom:$gap;
    width:100%;
    }

.sidebar-list {
    display: block;
    margin:0 0 $gap;
    padding:0 0 0;
    
    &__item {
        display: block;
        line-height:28px;
        }
    
    &__item :link,
    &__item :visited {
        color:$color-body;
        display: block;
        cursor:pointer;
        }
    
    &__item:hover :link,
    &__item:hover :visited {
        color:$color-primary;
        }
    
    &__item--active {
        font-family: $headings-font-family;
        }
    }

.product {
    background:white;
    border:1px solid $color-border;
    display: grid;
    margin-bottom:10px;
    grid-template-rows: 230px 70px;
    padding-top:$box-padding-s;
    
    &__title,
    &__image {
        align-self:center;
        justify-self:center;
        }
    
    &__title {
        font-family: $headings-font-family;
        color:$color-body;
        padding:0 $box-padding-s;
        text-align: center;
        }
    
    &__image > * {
        max-width:100%;
        max-height:200px;
        }
    
    &:hover {
        border:1px solid $color-primary;
        }
    }

.product-detail {
    
    &__image {
        width:100%;
        text-align: center;
        margin-bottom:$gap;
        border:1px solid $color-border;
        
        & > * {
            max-width:100%;
            padding:$gap 0;
            max-height:100%;
            }
        }
    
    &__title {
        font-size:1.8em;
        margin-top:0;
        }
    
    }


@media screen and (min-width: $screen-sm-min) { // 768
    
    .product-overview {
        display: grid;
        grid-template-columns: 270px 1fr;
        grid-gap: $gap;
        }
    
    .products {
        display: grid;
        grid-gap:calc(#{$gap} / 3);
        grid-template-columns: 1fr 1fr;
        }
    
    .product {
        margin-bottom:0;
        }
    
    .product-detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:$gap;
        
        &__image {
            position: relative;
            margin-bottom:0;
            min-height:300px;
            
            & > * {
                position: absolute;
                padding:0;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
                }
            }
        
        &__title {
            font-size: 2.5em;
            }
        
        &__content .button {
            margin-bottom:0;
            }
        }
        
    }


@media screen and (min-width: $screen-md-min) { //992
    
    .products {
        grid-template-columns: 1fr 1fr 1fr;
        }
        
    }