.breadcrumbs {
    background:white;
    }

.breadcrumbs-list {
    display: block;
    min-height:60px;
    margin:0;
    padding:$box-padding-half 0 0;
    
    &__item {
        display: inline-block;
        }
    
    &__item :link,
    &__item :visited {
        display:inline-block;
        color:$color-body;
        }
    
    &__item:hover :link,
    &__item:hover :visited {
        color:$color-primary;
        }
    
    &__item:after {
        content:' / ';
        }
    
    &__item:last-child {
        font-family: $headings-font-family;
        }
    &__item:last-child:after {
        content:'';
        }
    }