.row-content {
      background: $color-bg-grey;
      
      &__content {
            padding: $box-padding-s 0;
            }
      
      &__header {
            margin-bottom: 20px;
            font-size:1.8em;
            line-height:1.2em;
            }

      &:nth-of-type(odd) {
            background:white;
            }
      }


.row-content-split {
      display:flex;
      flex-direction:column;
      padding-left:0;
      padding-right:0;
      }

.row-content__header {
      
      &--highlight {
            color: $color-primary;
            font-size:1.2em;
            margin-bottom:$box-padding-s;
      }
}

.row-content-form {
      &__image {
            max-height:400px;
            }
      }

.row-form {
      &__section {
            background:white;
      }
}

.cta {
      text-align: center;
      background:$color-cta;
      padding:$box-padding-s;
      
      &__title {
            font-size: 2em;
            font-family: $headings-font-family;
            font-weight: 700;
            margin-bottom:$box-padding-s;
            color:white;
            }
      
      :link,
      :visited {
            background-color:$color-bg-darkgrey;
            margin:0;
            }
      }

.row-content__content {

      &--boxed {
            max-width:900px;
            margin:0 auto;
            }

      &--quoted .user-generated-content {
            padding-right:50px;
            background: url("../img/quotes.png") right top no-repeat;
            background-size:auto 50px;
            }

      &.row-content-split__section {
            padding:$box-padding-s;
            }

      }


@media screen and (min-width:$screen-xs-max) { //768
      
      .row-content {
      
            &__content {
                  padding: $box-padding-m;
            }
      }
      
      .row-content__header {
            font-size:2.7em;
            line-height:1.3em;
            margin:0 0 $box-padding-s 0;
            }
      
      .row-content-split {
            flex-direction:row-reverse;
            flex-wrap: wrap;
            
            &__section {
                  width:50%;
                  }
      
            &:nth-of-type(even) {
                  flex-direction:row;
                  }
            }
      
      .cta {
            padding:$box-padding-m 0;
            }


      .row-content__content {
            &--quoted .user-generated-content {
                  padding-right:100px;
                  background-size:auto 100px;
                  }

            &.row-content-split__section {
                  padding:$box-padding-m;
                  }

            }
            
      }

@media screen and (min-width: $screen-md-min) { // 992
      
      .row-content-split__section {
      
            &--image {
                  min-height: 300px;
                  max-height:600px;
                  }

            &--smallimage {
                  min-height: 410px;
                  max-height:600px;
                  }
            }
      
      .cta {
            padding:$box-padding-l 0;
            display: flex;
            justify-content:center;
            
            &__title {
                  display: inline-block;
                  margin-right:$box-padding-s;
                  margin-bottom:0;
                  }
            }
      
      }

@media screen and (min-width: $screen-lg-min) {   // 1200
      
      .row-content {
      
            &__content {
                  padding: $box-padding-l;
                  }
            }

      .row-content__content {

            &.row-content-split__section {
                  padding:$box-padding-l;
                  }

            }
      }
