//copy
@import url('https://fonts.googleapis.com/css?family=Montserrat:300, 700|Roboto:300,700&display=swap');


//@font-face {
//      font-family: 'Roboto Slab';
//      font-style: normal;
//      font-weight: 700;
//      text-rendering: optimizeLegibility;
//      src: url('/assets/fonts/roboto-condensed-v16-latin-700.eot'); /* IE9 Compat Modes */
//      src: local('Roboto Slab'), local('Roboto Slab'),
//      url('/assets/fonts/roboto-condensed-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//      url('/assets/fonts/roboto-condensed-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//      url('/assets/fonts/roboto-condensed-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//      url('/assets/fonts/roboto-condensed-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//      url('/assets/fonts/roboto-condensed-v16-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
//      }


