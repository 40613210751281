.list {
      display: block;
      padding-top:$gap;
      padding-bottom:$gap;
      
      &__grid {
            display: grid;
            grid-gap: $gap;
            grid-template-columns: 1fr;
            margin-bottom:0;
            padding:0;
            }
      
      &__grid--two-columns {
            grid-template-columns: 1fr 1fr;
            }
      
      &__grid--three-columns {
            grid-template-columns: 1fr 1fr 1fr;
            }
      
      &__item {
            list-style: none;
            }
      }

.listitem {
      &__title {
            font-family:$headings-font-family;
            font-size: 20px;
            }
      }

@media screen and (max-width:$screen-xs-max) {
      .list {
            &__grid {
                  grid-gap: $box-padding-s;
                  grid-template-columns: 1fr;
                  grid-template-rows: repeat(8, auto-fit);
                  }
            }
      }