body {
  -webkit-font-smoothing: antialiased !important;
}

h1,h2,h3,h4,h5,h6 {
  //margin: 0;
  //padding: 0;
  //font-size: 100%;
  //font-weight: normal;
}

nav {
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

a {
  &:focus {
    outline: none;
    text-decoration: none;
  }
}