$headings-font-family: "Roboto", sans-serif;
$headings-font-weight: 700;
$font-family-base: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-base-weight:300;


$color-body: rgb(33,33,33);

$color-bg-grey: rgb(247, 247, 247);
$color-bg-lightgrey: rgb(243, 243, 243);
$color-bg-darkgrey: rgb(33, 33, 33);

$color-primary: rgb(234, 81, 21);
$color-secondary: rgb(30,48,94);
$color-secondary-hover: rgb(22,35,68);
$color-cta: rgb(0, 79, 81);
$color-cta-dark: rgb(0, 44, 45);
$color-border: rgb(230, 230, 230);

$color-gradient-start:rgb(217, 139, 22) ;
$color-gradient-end: rgb(252, 198, 83);
$color-gradient-dark-start:rgb(228, 113, 0) ;
$color-gradient-dark-end: rgb(255, 174, 0);

// Sizes
$menuheight: 100px;
$menu-bg-color: $color-cta;
$menu-bg-color-scrolled:$color-bg-darkgrey;

// box vars
$row-padding: 25px;
$box-padding-s: 25px;
$box-padding-m: 50px;
$box-padding-l: 100px;
$gap: $box-padding-m;
$tile-padding: $box-padding-s;
$menuh: 60px;
$box-padding-half: calc(#{$box-padding-s} / 2);

    
// hamburger vars
$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 3px !default;
$hamburger-layer-width                     : 28px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 5px !default;
$hamburger-layer-color                     : white !default;
$hamburger-layer-border-radius             : 2px !default;
$hamburger-hover-opacity                   : 0.9 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(90%) !default;

$hamburger-types: (
    slider
) !default;

@media screen and (min-width: $screen-lg-min) { // 1200
    
    $row-padding: 100px;
    
    }